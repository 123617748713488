import React from 'react';
import { makeStyles } from '@mui/styles';

import FormField from 'common/oldJavascripts/components/Base/FormField';
import DatePicker from 'common/oldJavascripts/components/Base/DatePickerField';
import Select from 'common/oldJavascripts/components/Base/Select';

import useWorkflowCategories from '../hooks/useProjectWorkflowCategories';
import useProjectDepartments from '../hooks/useProjectDepartments';
import useProjectEmployees from '../hooks/useProjectEmployees';

import FilterActions from './FilterActions';

const useStyles = makeStyles({
  navBar: {
    display: 'grid',
    gridTemplateColumns: '13% 13% repeat(3, 24%)',
    gridTemplateRows: '100%',
    gridColumnGap: '5px',
    gap: '5px',
    clear: 'both',
    alignItems: 'flex-start',
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    color: '#f00',
  },
});

const Filter = props => {
  const { projectId, filters, onChange } = props;
  const classes = useStyles();

  const { fromDate, toDate, documentType, department, employee } =
    filters || {};

  const {
    data: wfCats = [],
    loading: wfCatsLoading,
    error: wfCatsError,
  } = useWorkflowCategories(projectId);

  const {
    data: departments = [],
    loading: departmentsLoading,
    error: departmentsError,
  } = useProjectDepartments(projectId);

  const {
    data: employees = [],
    loading: employeesLoading,
    error: employeesError,
  } = useProjectEmployees(projectId);

  const changeFilters = newData => {
    onChange(filters => ({
      ...filters,
      ...newData,
    }));
  };

  const onChangeDate = (key, date) => {
    if (key === 'from') {
      changeFilters({ fromDate: date });
      if (!date || date.isAfter(toDate)) {
        changeFilters({ toDate: null });
      }
    } else {
      changeFilters({ toDate: date });
    }
  };

  const formattedTags = wfCats.map(tag => ({
    value: tag.id,
    label: tag.name,
  }));

  const formattedDepartments = departments.map(department => ({
    value: '' + department.id,
    label: department.name,
  }));

  const employeeOptions = employees.map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${lastName}, ${firstName}`,
  }));

  const queryError = err => <div className={classes.error}>{err.message}</div>;

  const ready = !wfCatsLoading && !departmentsLoading && !employeesLoading;

  return (
    <>
      <div className={classes.navBar}>
        <FormField label="From" classes={{ label: classes.fullWidth }}>
          <DatePicker
            id="filter_from_date"
            name="from_date"
            value={fromDate}
            selected={fromDate}
            onChange={date => onChangeDate('from', date)}
            className={classes.fullWidth}
          />
        </FormField>
        <FormField label="To" classes={{ label: classes.fullWidth }}>
          <DatePicker
            id="filter_to_date"
            name="to_date"
            value={toDate}
            selected={toDate}
            minDate={fromDate}
            onChange={date => onChangeDate('to', date)}
            className={classes.fullWidth}
          />
        </FormField>
        <div data-test-id="OffersDocuments-documentTag">
          {wfCatsError && queryError(wfCatsError)}
          {!wfCatsError && (
            <FormField label="Document Tag">
              <Select
                isLoading={wfCatsLoading}
                options={formattedTags}
                multiple={true}
                placeholder="Select..."
                value={documentType || []}
                onChange={val => changeFilters({ documentType: val })}
                searchable={true}
              />
            </FormField>
          )}
        </div>
        {departmentsError && queryError(departmentsError)}
        {!departmentsError && (
          <div>
            <FormField label="Department">
              <Select
                isLoading={departmentsLoading}
                options={formattedDepartments}
                multiple={true}
                placeholder="Select..."
                value={department}
                onChange={val => changeFilters({ department: val })}
                searchable={true}
              />
            </FormField>
          </div>
        )}
        {employeesError && queryError(employeesError)}
        {!employeesError && (
          <div>
            <FormField label="Employee Name">
              <Select
                options={employeeOptions}
                isLoading={employeesLoading}
                label={''}
                onChange={val => changeFilters({ employee: val })}
                value={employee}
                multiple={true}
                clearable={true}
                searchable={true}
              />
            </FormField>
          </div>
        )}
      </div>
      <FilterActions {...{ ready, projectId, filters, onChange }} />
    </>
  );
};

export default Filter;
